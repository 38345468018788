import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Cta from "../components/cta";
import * as Styles from "../styles/effect.module.css";

const ReturnPolicy = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          Returns and Exchanges Policy - KATSANA® Integrated Fleet Management &
          Industrial Operation Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.katsana.com/returns-and-exchanges-policy/"
        />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.katsana.com/returns-and-exchanges-policy/"
        />
        <meta
          property="og:title"
          content="Returns and Exchanges Policy - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0  md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto ">
          <h3 className="pt-24 md:pt-12 text-lg md:text-2xl text-bluegray">
            Important stuff that you need to know
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            Returns and Exchanges Policy
          </h1>
        </div>
      </div>

      <div className=" bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-white relative pb-36">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
        <div className="max-w-4xl p-8 xl:p-16 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,0)] to-[hsla(204,26%,99%,0)] via-white text-gray-800  relative">
          <h4 className="mt-6 text-xl font-medium">
            Returns & Exchanges Policy
          </h4>
          <p className="mt-2 leading-7">
            We believe in our solutions and strive to ensure that you are
            satisfied with them. We will do our best to take care of customers –
            our philoshopy is to deal with you fairly and reasonably; we hope
            you will be fair and reasonable with us as well.
            <br />
            <br />
            To proceed with returns and exchanges, please fill up this return
            form and label.
          </p>
          <h4 className="mt-6 text-xl font-medium">
            Returning by Mail (Malaysia)
          </h4>
          <p className="mt-2 leading-7">
            Send in your device to this address within 60 days of subscription
            starts or purchase. Your return will be processed within 14 business
            days.
          </p>
          <h4 className="mt-6 text-xl font-medium">
            Returning in branches (Malaysia)
          </h4>
          <p className="mt-2 leading-7">
            Just bring the device to any one of our branches within 60 days of
            subscription starts or purchase.
          </p>
          <h4 className="mt-6 text-xl font-medium">Exchanges</h4>
          <p className="mt-2 leading-7">
            Upon returning of device, you may opt to receive a new tracking
            device or receive a refund.
          </p>
          <h4 className="mt-6 text-xl font-medium">Refunds</h4>
          <p className="mt-2 leading-7">
            If you ordered by credit card, we will credit your account within 30
            business days. This will shown on your next statement, depending on
            issuing bank and/or billing cycle.
            <br />
            <br />
            If you have requested an exchange, your account will be charged for
            the new item and credited for the return.
            <br />
            <br />
            Unfortunately, we cannot provide refund for:
            <br />
            <br />
            a. Work exercised, such as installations/inspections or removal of
            devices.
            <br />
            b. Shipping charges.
            <br />
            c. Third party damages or loss of profit resulting from installation
            or removal of our tracking devices.
            <br />
            d. Renewal payments.
          </p>
          <h3 className="mt-12 text-2xl font-medium">
            Frequently Asked Questions
          </h3>
          <h4 className="mt-6 text-xl font-medium">
            What is your return policy?
          </h4>
          <p className="mt-2 leading-7">
            Returns are handled on a case-by-case basis with the ultimate
            objective of satisfying the customer. We stand behind our solution
            and services, and strive to ensure customers are satisfied with them
            – our philoshopy is to deal with you fairly and reasonably; we hope
            you will be fair and reasonable with us as well.
          </p>
          <h4 className="mt-6 text-xl font-medium">
            Why dont you have a return policy?
          </h4>
          <p className="mt-2 leading-7">
            We hope to build trust with our customers, and create a mutually
            long and beneficial relationship. Standing behind what we provide is
            a big part of that. We think many customers choose us because they
            that we will do our best to ensure a pleasant experience with us.
          </p>
          <h4 className="mt-6 text-xl font-medium">
            Do I need a receipt from my purchase?
          </h4>
          <p className="mt-2 leading-7">
            Receipts will easy our work in locating the purchases, but it is not
            necessary. Should the receipt is missing, we should still be able to
            find the purchase in other ways. We however may ask you for more
            information so we can better assist your request.
          </p>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default ReturnPolicy;
